import { PlusCircledIcon, StarFilledIcon } from "@radix-ui/react-icons";
import { Box, Card, Flex, Heading, Link, Text } from "@radix-ui/themes";
import classNames from "classnames";
import { useNavigate } from "react-router-dom";
import { useCartStore } from "../../../state";
import styles from "./ProductCard.module.scss";

interface WholesaleProductCardProps {
  product: any;
  scroll?: boolean;
}

const WholesaleProductCard: React.FC<WholesaleProductCardProps> = ({ product, scroll }) => {
  const navigate = useNavigate();
  const addOneToCart = useCartStore((state) => state.addOneToCart);
  const quickAdd = (event: any) => {
    event.stopPropagation();
    addOneToCart(product.id, product.path);
  };


  return (
    <Flex
      onClick={() => {
        navigate(`/shop/${product?.path.toLowerCase().replaceAll("_", "-")}`);
      }}
      className={classNames("testimonial-card", styles.WholesaleProductCard, `${scroll ? styles.Scroller : ""}`)}
      mb="6"
    >
      <Flex
        direction={"column"}
        justify="between"
        align={"center"}
        height={"100%"}
      >
        <Flex direction={"column"} justify="between" align={"center"}>
          <Flex gap="1" direction={"column"} align="center">
            <img
              width="100%"
              src={product.image}
              alt={`Image of ${product.title}`}
            />

            <Flex direction={"column"} justify="between" align={"center"} p="4" pt="2" > 

            <Heading as="h1" className={styles.ProductTitle}>
              {product?.title}
            </Heading>

          <Flex my="3" direction={"column"} align="center">
            
            <Text size={"5"}>
                <span className={styles.OldPrice}>£{Number(product?.oldPrice).toFixed(2)}</span>
            </Text>
            
              <Text size={"7"} color="orange" >
                <span className={styles.Price}>£{Number(product?.price).toFixed(2)}</span>
                <Text mb="3" color="orange" size={"2"}>
                (£{Number(product?.unitPrice).toFixed(2)}) per unit.
              </Text>
              </Text>
          </Flex>
                       

            <Link className={styles.QuickAddLink} onClick={(e) => quickAdd(e)}>
          <Flex align="center" gap="1">
            Quick Add
            <PlusCircledIcon />
          </Flex>
        </Link>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default WholesaleProductCard;
