import { Flex } from "@radix-ui/themes";
import { FC } from "react";
import { useCartStore } from "../../../state";
import ProductCard from "../product-card/ProductCard";
import styles from "./ScrollingShop.module.scss";

const ScrollingShop: FC = () => {
  const productsData = useCartStore((state) => state.productsData);
  const filteredProductsData = Object.values(productsData).filter(
    (product) => !product.comingSoon && !product.isWholesale
  );

  return (
    <>
      <Flex className={styles.ScrollContainer}>
        {filteredProductsData.map((product, index) => (
          <ProductCard product={product} scroll />
        ))}
      </Flex>
    </>
  );
};

export default ScrollingShop;
