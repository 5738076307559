import ReactDOM from 'react-dom/client'
import App from './App'
import "@radix-ui/themes/styles.css";
import './shared/scss/global.scss'

import './CursedTides/scss/productStyles.scss'
import './Jinx/scss/productStyles.scss'
import './SneakyGoose/scss/productStyles.scss'
import './Noise/scss/productStyles.scss'
import './TopBrass/scss/productStyles.scss'
import './PunchyKicky/scss/productStyles.scss'

import awsExports from "./aws-exports";
import { Amplify } from "aws-amplify";

Amplify.configure(awsExports);

import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './shared/components/scroll-to-top/scrollToTop';

ReactDOM.createRoot(document.getElementById('root')!).render(
    <BrowserRouter>
    <ScrollToTop/>
      <App />
    </BrowserRouter>
)
