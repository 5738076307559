import { Box, Button, Flex, Heading } from "@radix-ui/themes";
import { Link } from "react-router-dom";
import CustomCarousel from "../../shared/components/carousel/carousel";
import EmailSubmission from "../../shared/components/email-submission/email-submission";
import ExplainerSection from "../../shared/components/explainer-section/ExplainerSection";
import MarketingSection from "../../shared/components/marketing-section/MarketingSection";
import RulesDownload from "../../shared/components/rules-download/rulesDownload";
import carouselData from "../../shared/constants/homeCarousel.json";
import gooseMarketing from "../../shared/constants/sneakyGooseMarketingData.json";
import HowToPlayGoose from "../../SneakyGoose/components/HowToPlayGoose/HowToPlayGoose";
import { routesConfig } from "../../App";

const SneakyGoose = () => {
  return (
    <>
      <Flex className="MainContainer" direction={"column"}>
        <Flex
          height={{ initial: "300px", sm: "450px" }}
          justify={{ initial: "center", md: "end" }}
          mr={{ initial: "0", md: "9" }}
          pr={{ initial: "0", md: "9" }}
          align={"center"}
        >
          <Heading as="h1" size={"9"} align="center" className="HugeText">
            SNEAKY <br /> GOOSE
          </Heading>
        </Flex>
        <Flex direction={{ initial: "column", md: "row" }} justify="center">
          <Flex
            justify="center"
            align="center"
            direction="column"
            mb="9"
            mx="9"
          >
            <Heading mb="4" as="h2" size={"9"} align="center">
              SCORE TRACKER
            </Heading>

            <Link to={routesConfig["SNEAKY_GOOSE_SCORE_TRACKER"].path}>
              <Button size="4">Go to Goose Score Tracker</Button>
            </Link>
          </Flex>

          <Flex
            justify="center"
            align="center"
            direction="column"
            mb="9"
            mx="9"
          >
            <Heading mb="4" as="h2" size={"9"} align="center">
              RULES
            </Heading>

            <RulesDownload productName="SneakyGoose" />
          </Flex>
        </Flex>

        <MarketingSection data={gooseMarketing} />

        <ExplainerSection
          title="Sneaky Goose"
          description="Swap, Slam and Outsmart the Gander"
          listItems={["2-5 Players", "30 Minute Playtime", "Ages 8+"]}
          image="/shared/images/GooseBox.webp"
          productName={"SNEAKY_GOOSE"}
        />

        <HowToPlayGoose />

        <EmailSubmission />

        <CustomCarousel cards={carouselData} />
      </Flex>
    </>
  );
};

export default SneakyGoose;
