import {
  Box,
  Container,
  Text,
  Grid,
  Heading,
  Section,
  Flex,
} from "@radix-ui/themes";
import styles from "./HowToPlayCursedTides.module.scss";
import Build from "/shared/images/BuildFleets2.webp";
import Plunder from "/shared/images/Plunder2.webp";
import Stash from "/shared/images/Stash2.webp";
import Action from "/shared/images/Action.webp";

const HowToPlayCursedTides: React.FC = () => {
  return (
    <Box className={styles.HowToPlayCursedTidesContainer}>
      <Box className={styles.Cover}></Box>
      <Container pb="9" className={styles.ContentContainer}>
      <Flex align="center" flexGrow={"1"} direction={"column"}>
      <Heading as="h2" align="center" size={"9"} mb="4">
            HOW TO PLAY
          </Heading>
      
          <Text size={"6"}>
            To claim victory, gather three full Fleets of Ships before any other
            player.
          </Text>
        </Flex>

        <Flex direction={{ initial: "column-reverse", md: "row" }} pt="5">
          <Flex align="center" justify="center" px="7" className="halfWidth">
            <img src={Build} alt="Build Fleets" width={"100%"} />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Build Yer Fleets
            </Heading>
            <Text mt="3">
              Each player works to amass three full fleets of ships. To do this,
              players must draw and play cards, adding ships to their armada
              while defending against other pirates' schemes. Successfully
              completing and protecting these fleets from the treacherous
              actions of opponents is key to becoming the most notorious pirate
              on the high seas. Plan your moves wisely, and let the quest for
              maritime supremacy begin!
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }} pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Plunder Yer Enemies
            </Heading>
            <Text  mt="3">
              The action heats up with a variety of Plunder cards that add
              strategy to the game. These cards allow you to steal treasure from
              other players. If you have either of the fleets depicted on a
              Plunder card, you can execute a plunder action, stealing the
              corresponding amount from your opponent based on the number of
              cards you have of that fleet. A Supply Ship adds 150 to the
              plunder amount, while a Warship adds 200. Using these cards
              effectively can turn the tide of the game in your favour, making
              them essential for any pirate seeking dominance on the high seas.
            </Text>
          </Flex>
          <Flex align={"center"} px="7" justify="center">
            <img src={Plunder} alt="Plunder Cards" width={"100%"} />
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }} pt="5">
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            <img src={Stash} alt="Treasure Cards" width={"100%"} />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Stash Yer Treasure
            </Heading>
            <Text mt="3">
              Stashing treasure is critical for any aspiring pirate Lord. Your
              treasure stash serves as both a shield and a sword, protecting
              your assets from rival pirates' devious schemes while providing
              the means to pay off debts and fund your exploits. Carefully
              managing your treasure withstand raids, plunders, and trades
              without losing your hard-earned ships. Remember, in the race for
              maritime supremacy, a pirate's fortune is only as secure as their
              treasure.
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }} pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={{ initial: "8", sm: "9" }}>
              Take Command
            </Heading>
            <Text mt="3">
              Powerful Action cards that offer strategic advantages and
              defensive maneuvres. Cards like "Parlay" players to negate enemy
              actions, protecting their assets from rival pirates' schemes.
              Cards like "Commandeer" and "Overthrow" let players take command
              of their ships or entire fleets, their armada and weakening an
              opponent's. Mastering the use of Action cards is crucial for not
              only defending your own fleets and treasure but also for asserting
              your power and securing ultimate pirate glory.
            </Text>
          </Flex>
          <Flex align={"center"} px="7" justify="center">
            <img src={Action} alt="Action Cards" width={"100%"} />
          </Flex>
        </Grid>
      </Container>
    </Box>
  );
};

export default HowToPlayCursedTides;
