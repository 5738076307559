import { Flex, Grid, Container } from "@radix-ui/themes";
import ContactWholesale from "../../shared/components/contact-us-wholesale/contactWholesale";
import Header from "../../shared/components/header/header";
import ProductCard from "../../shared/components/product-card/ProductCard";
import WholesaleProductCard from "../../shared/components/product-card/WholesaleProductCard";
import WhyChooseUs from "../../shared/components/why-worgan-games/WhyWorganGame";
import { useCartStore } from "../../state";

const Wholesale = () => {
  const productsData = useCartStore((state) => state.productsData);
  const filteredProductsData = Object.values(productsData).filter(
    (product) => product.isWholesale
  );
  return (
    <>
      <Header
        heading={"BUY WHOLESALE"}
        subtext={
          "Choose from 10, 20, or 50 packs. Need 100+ units? Contact us below!"
        }
        blur={false}
        thin={true}
      />

      <Flex gap="9" align="center" direction="column" justify="start" px="5" mb="9">
        <Container>
          <Grid columns={{ initial: "1", sm: "2", md: "3" }} gap="4" mt="6">
            {filteredProductsData.map((product) => (
              <WholesaleProductCard product={product} />
            ))}
          </Grid>
        </Container>
        <ContactWholesale />
        <WhyChooseUs />
      </Flex>
    </>
  );
};

export default Wholesale;
