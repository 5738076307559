import { Box, Button, Flex } from "@radix-ui/themes";
import { useNavigate } from "react-router-dom";
import { routesConfig } from "../../App";
import CustomCarousel from "../../shared/components/carousel/carousel";
import EmailSubmission from "../../shared/components/email-submission/email-submission";
import ScrollingShop from "../../shared/components/ScrollingShop/ScrollingShop";
import WhyChooseUs from "../../shared/components/why-worgan-games/WhyWorganGame";
import carouselData from "../../shared/constants/homeCarousel.json";
import styles from "./Home.module.scss";
import logo from "/shared/images/logo.webp";

const Home = () => {
  const navigate = useNavigate();

  const goToAmazon = () => {
    window.open(
      "https://www.amazon.co.uk/stores/WorganGames/page/68F553A4-F237-4F81-A345-0989E4817C5F",
      "_blank"
    );
  };

  return (
    <Box>
      <Flex className={styles.TopSectionContainer}>
        <Flex direction={"column"} className={styles.SideColumn}>
          <Flex
            className={styles.SneakySection}
            justify={"start"}
            onClick={() => {
              navigate(routesConfig.SNEAKY_GOOSE.path);
            }}
          ></Flex>
          <Flex
            className={styles.JinxSection}
            justify={"start"}
            onClick={() => {
              navigate(routesConfig.JINX.path);
            }}
          ></Flex>
        </Flex>

        {/* <Flex
          className={styles.WorganGamesSection}
          direction="column"
          py="9"
          justify="between"
          align="center"
          gap="9"
        >
          <Box display={{initial:"none", md:"block"}}className={styles.LogoBox}>
            <img src={logo} alt="Worgan Games Logo" />
          </Box>

          <Button
            size={"4"}
            mb={{initial:"0", md:"9"}}
            onClick={() => {
              navigate(routesConfig.SHOP.path);
              // goToAmazon();
            }}
          >
            SHOP NOW
          </Button>
        </Flex> */}

        <Flex direction={"column"} className={styles.SideColumn}>
          <Flex
            className={styles.BrassSection}
            justify={"start"}
            onClick={() => {
              navigate(routesConfig.TOP_BRASS.path);
            }}
          ></Flex>

          <Flex
            className={styles.NoiseSection}
            justify={"start"}
            onClick={() => {
              navigate(routesConfig.GUESS_THAT_NOISE.path);
            }}
          ></Flex>
        </Flex>
        <Flex direction={"column"} className={styles.SideColumn}>
          <Flex
            className={styles.PunchySection}
            justify={"start"}
            onClick={() => {
              navigate(routesConfig.PUNCH_KICK.path);
            }}
          ></Flex>
          <Flex
            className={styles.CursedSection}
            justify={"start"}
            onClick={() => {
              navigate(routesConfig.CURSED_TIDES.path);
            }}
          ></Flex>
        </Flex>
      </Flex>

      <EmailSubmission />


<ScrollingShop />

      <Flex my="9">
        <WhyChooseUs />
      </Flex>

      <CustomCarousel cards={carouselData} />
    </Box>
  );
};

export default Home;
