import {
  Box,
  Button,
  Container,
  Flex,
  Grid,
  Heading,
  Section,
  Text,
} from "@radix-ui/themes";
import styles from "./HowToPlayTopBrass.module.scss";
import ReserveDown from "/shared/images/SecretReserves.webp";
import Play from "/shared/images/RankStructure.webp";
import Setup from "/shared/images/SetupGraphic.webp";
import ReservesUp from "/shared/images/PlayingReserves.webp";
import Swap from "/shared/images/InitialSwapGraphic.webp";
import brassLogo from "/shared/images/TopBrassLogo.webp";
import DirectOrder from "/shared/images/DirectOrderCards.webp";

const HowToPlayTopBrass: React.FC = () => {
  return (
    <Box className={styles.HowToPlayGooseContainer}>
      <Box className={styles.Cover}></Box>

      <Container pb="9" className={styles.ContentContainer}>
        <Flex justify="center" flexGrow={"1"}>
          <Heading as="h1" size={"9"} my="5">
            How to Play
          </Heading>
        </Flex>
        <Flex justify="center" flexGrow={"1"}>
          <Text size={"6"}>The first to get rid of all their cards wins.</Text>
        </Flex>
        <Section>
          <Flex justify="center" align="center">
            <img width={"50%"} src={brassLogo} alt="Cards setup graphic" />
          </Flex>
        </Section>

        <Flex direction={{ initial: "column-reverse", md: "row" }}>
          <Flex align="center" justify="center" className="halfWidth">
            <img src={Setup} alt="Score tracker logo" width={"100%"} />
          </Flex>

          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Setup
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              <ul>
                <li>1. Deal each player 3 face down reserve cards.</li>
                <li>
                  2. Deal each player 3 face up reserve cards, placed on top of
                  the face down reserves.
                </li>
                <li>3. Deal each player 3 cards for their hand.</li>
                <li>
                  4. The remaining cards are placed into the centre to form a
                  draw pile.
                </li>
              </ul>
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }}>
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Swapping Reserves
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              <ol>
                <li>
                  Before starting players may swap any number of their face-up
                  reserve cards with cards from their hand.
                </li>
              </ol>
            </Text>
          </Flex>
          <Flex align={"center"} justify="center">
            <img src={Swap} alt="Card grid setup" width={"100%"} />
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }}>
          <Flex pr={{ initial: "", md: "9" }}align={"center"} justify="center" className="halfWidth">
            <img src={Play} alt="Swapping cards example" width={"100%"} />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Your Turn
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              <ol>
                <li>
                  On your turn you need to play a card from your hand that
                  matches or outranks the top card on the play pile.
                </li>
                <li>
                  A card may only be played onto any card with a higher rank than
                  its own.
                </li>
                <li>
                  If there are still cards in the draw pile you need to pick up
                  a card to maintain a minimum of 3 cards.
                </li>
                <li>
                  If you are unable to play a card then you must pick up the
                  play pile.
                </li>
              </ol>
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }}>
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Face Up Reserve Cards
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Once you have played all your hand cards and there are no draw
              cards left in the pile you can play your face up reserve cards.
            </Text>
          </Flex>
          <Flex align={"center"} justify="center">
            <img src={ReservesUp} alt="Slamming example" width={"100%"} />
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }}>
          <Flex pr={{ initial: "", md: "9" }} align={"center"} justify="center" className="halfWidth">
            <img src={ReserveDown} alt="Successful slam graphic" width={"100%"} />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
            Face Down Reserve Cards
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Once you have played all you hand cards and face up reserve
              cards you must now blindly play your face down reserve cards.
            </Text>
          </Flex>
        </Flex>

        <Grid columns={{ initial: "1", md: "2" }}>
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Winning
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              No cards left to play? Congratulations soldier! You've won! Consider yourself one of the Top Brass.
            </Text>
            
          </Flex>
          <Flex align={"center"} justify="center">
            <img src={brassLogo} alt="Top Brass logo" width={"50%"} />
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }}>
          <Flex pr={{ initial: "", md: "9" }} align={"center"} justify="center" className="halfWidth">
            <img src={DirectOrder} alt="Successful slam graphic" width={"100%"} />
          </Flex>
          <Flex justify="center" py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
            Direct Order Cards
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Be aware of Direct Order cards, they have the power to drastically change the dynamic of the game, so use them wisely!
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default HowToPlayTopBrass;
