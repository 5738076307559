import { Box, Button, Flex, Heading } from "@radix-ui/themes";
import CustomCarousel from "../../shared/components/carousel/carousel";
import carouselData from "../../shared/constants/homeCarousel.json";
import EmailSubmission from "../../shared/components/email-submission/email-submission";
import punchLogo from "../../../public/shared/images/PunchKickLogo2.webp";
import punchMarketing from "../../shared/constants/punchyMarketingData.json";
import MarketingSection from "../../shared/components/marketing-section/MarketingSection";
import HowToPlayPK from "../../PunchyKicky/components/HowToPlayPK";
import { Link } from "react-router-dom";
import { routesConfig } from "../../App";
import RulesDownload from "../../shared/components/rules-download/rulesDownload";

const PunchyKicky = () => {
  return (
    <>
      <Flex className="MainContainer" direction={"column"}>
        <Flex justify="center" align={"center"}>
          <img src={punchLogo} />
          <Flex
            justify="center"
            py={"5"}
            px={{ initial: "1", md: "9" }}
            display={{ initial: "none", md: "flex" }}
          >
            <Heading
              align={{ initial: "center", md: "left" }}
              as={"h1"}
              size={{ initial: "8", md: "9" }}
            >
              Ages 8 plus <br /> 3-6 Players
              <br />
              15-30 minute gametime
            </Heading>
          </Flex>
        </Flex>

        <Flex direction={{ initial: "column", md: "row" }} justify="center">
          <Flex
            justify="center"
            align="center"
            direction="column"
            mb="9"
            mx="9"
          >
            <Heading mb="4" as="h2" size={"9"} align="center">
              SCORE TRACKER
            </Heading>

            <Link to={routesConfig["PUNCHY_KICKY_SCORE_TRACKER"].path}>
              <Button size="4">Go to Score Tracker</Button>
            </Link>
          </Flex>

          <Flex
            justify="center"
            align="center"
            direction="column"
            mb="9"
            mx="9"
          >
            <Heading mb="4" as="h2" size={"9"} align="center">
              RULES
            </Heading>

            <RulesDownload productName="PunchyKicky" />
          </Flex>
        </Flex>

        <MarketingSection data={punchMarketing} />

        <HowToPlayPK />
        <EmailSubmission />
        <Box className="ComingSoonCarouselContainer">
          <CustomCarousel cards={carouselData} />
        </Box>
      </Flex>
    </>
  );
};

export default PunchyKicky;
