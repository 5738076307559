import { Container, Flex, Grid, Text } from "@radix-ui/themes";
import EmailSubmission from "../../shared/components/email-submission/email-submission";
import ProductCard from "../../shared/components/product-card/ProductCard";
import { useCartStore } from "../../state";
import styles from "./Shop.module.scss";

const Shop = () => {
  const productsData = useCartStore((state) => state.productsData);
  const filteredProductsData = Object.values(productsData).filter(
    (product) => !product.comingSoon && !product.isWholesale
  );

  return (
    <>
      <Flex direction={"column"} className={styles.ShopPageContainer}>
        <Container>
          <Flex justify={"center"} direction="column">
            {filteredProductsData.length === 0 ? (
              <>
                <Text size="7" mt="3" mb="9" align={"center"}>
                  Our first few games are due to be released very soon. <br />
                  Enter your email below and we will let you know when they are
                  released!
                </Text>
                <EmailSubmission />
              </>
            ) : (
              <></>
            )}
          </Flex>
          <Grid columns={{ initial: "1", sm: "2", md: "3" }} gap="4" mt="6">
            {filteredProductsData.map((product) => (
              <ProductCard product={product} />
            ))}
          </Grid>
        </Container>
      </Flex>
    </>
  );
};

export default Shop;
