import {
  Box,
  Container,
  Text,
  Grid,
  Heading,
  Flex,
} from "@radix-ui/themes";
import styles from "./HowToPlayNoise.module.scss";
import Setup from "/shared/images/NoiseSetup.webp";
import Start from "/shared/images/StartingGraphic.webp";
import Guess from "/shared/images/GuessingThatNoise.webp";
import Scoring from "/shared/images/Scoring.webp";
import EndGame from "/shared/images/Endgame.webp";

const HowToPlayNoise: React.FC = () => {
  return (
    <Box className={styles.HowToPlayNoiseContainer}>
      <Box className={styles.Cover}></Box>
      <Container pb="9" className={styles.ContentContainer}>
        <Flex align="center" flexGrow={"1"} direction={"column"}>
          <Heading as="h2" size={"9"} mb="4">
            HOW TO PLAY
          </Heading>
          <Text size={{ initial: "5", md: "7" }}>
            Get the most points by the end of the game to win!
          </Text>
        </Flex>

        <Flex
          align="center"
          direction={{ initial: "column-reverse", md: "row" }}
          pt="5"
        >
          <Flex align="center" justify="center" px="7" className="halfWidth">
            <img src={Setup} alt="Jinx Start" width="100%" />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Setup
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="5">
              <ol type="1">
                <li>
                  Simply shuffle the deck and place 15 cards face down into the
                  center of the play area.
                </li>
                <li>
                  Choose which player has the silliest sounding name. They are
                  the first noise maker.
                </li>
              </ol>
            </Text>
          </Flex>
        </Flex>

        <Grid align="center" columns={{ initial: "1", md: "2" }} pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Choose a noise
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              The noise maker chooses a noise on the card to immitate. The more
              points the harder the noise!
            </Text>
          </Flex>
          <Flex align="center" px="7" justify="center">
            <img src={Start} alt="Starting Graphic" width={"100%"} />
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }} pt="5">
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            <img src={Guess} alt="Guess graphic" width={"100%"} />
          </Flex>
          <Flex
            py="9"
            justify={"center"}
            direction={"column"}
            className="halfWidth"
          >
            <Heading as="h2" size={"9"}>
              Guess That Noise
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              No words! No gestures!
            </Text>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              All Players guess that noise shouting as many guesses as they
              wish.
            </Text>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              The player who correctly guesses goes next!
            </Text>
          </Flex>
        </Flex>

        <Grid align={"center"} columns={{ initial: "1", md: "2" }} pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Scoring
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Keep track of your score by placing your cards into 1, 2 and 3
              point piles.
            </Text>
          </Flex>
          <Flex align={"center"} px="7" justify="center">
            <img src={Scoring} alt="Scoring Graphic" width={"100%"} />
          </Flex>
        </Grid>

        <Flex
          align={"center"}
          direction={{ initial: "column-reverse", md: "row" }}
          pt="5"
        >
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            <img src={EndGame} alt="Add up Graphic" width={"100%"} />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Winning
            </Heading>
            <Text mt="3" size={{ initial: "5", md: "7" }}>
              After 15 cards, tally up your score. The highest score wins!
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default HowToPlayNoise;
