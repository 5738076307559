import { Button} from "@radix-ui/themes";
import { downloadFile } from "../../util/s3Helpers";

interface RulesDownloadProps {
  productName: string;
  cardRef? : boolean;
}

const filesBucket = import.meta.env.VITE_FILES_BUCKET;

const RulesDownload: React.FC<RulesDownloadProps> = ({ productName, cardRef }) => {
  const fileURL = `https://${filesBucket}.s3.eu-west-1.amazonaws.com/${productName}Rules.pdf.gz`;

  return (
    <Button size="4" onClick={() => downloadFile(fileURL)}>
      {cardRef ? "Download Card Reference" : "Download Rules"}
      </Button>
  );
};

export default RulesDownload;
