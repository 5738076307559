import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Text,
  TextField,
} from "@radix-ui/themes";
import { useState } from "react";
import { usePunchyKickyStore } from "../../state";
import styles from "./PunchyKicky.module.scss";
import React from "react";
import { routesConfig } from "../../App";
import { Link, useNavigate } from "react-router-dom";
import logo from "/shared/images/PunchKickLogo2.webp";

const rankedPlace = ["🏆", "🥈", "🥉", "🥄", "💩"];

const PunchyKickyScoreTracker = () => {
  const navigate = useNavigate();

  const [name1, setName1] = useState("");
  const [name2, setName2] = useState("");
  const [name3, setName3] = useState("");
  const [name4, setName4] = useState("");
  const [name5, setName5] = useState("");
  const [name6, setName6] = useState("");

  const [knockouts1, setKnockouts1] = useState<number | null>(null);
  const [knockouts2, setKnockouts2] = useState<number | null>(null);
  const [knockouts3, setKnockouts3] = useState<number | null>(null);
  const [knockouts4, setKnockouts4] = useState<number | null>(null);
  const [knockouts5, setKnockouts5] = useState<number | null>(null);
  const [knockouts6, setKnockouts6] = useState<number | null>(null);

  const [punchyIndex, setPunchyIndex] = useState<number | null>(null);
  const recordScoresForRound = usePunchyKickyStore(
    (state) => state.recordScoresForRound
  );

  const playerNames = [
    {
      value: name1,
      setter: setName1,
      placeholder: "Enter Fighter 1's Name",
      required: true,
      knockouts: knockouts1,
      knockoutsSetter: setKnockouts1,
    },
    {
      value: name2,
      setter: setName2,
      placeholder: "Enter Fighter 2's Name",
      required: true,
      knockouts: knockouts2,
      knockoutsSetter: setKnockouts2,
    },
    {
      value: name3,
      setter: setName3,
      placeholder: "Enter Fighter 3's Name",
      knockouts: knockouts3,
      knockoutsSetter: setKnockouts3,
    },
    {
      value: name4,
      setter: setName4,
      placeholder: "Enter Fighter 4's Name (Optional)",
      knockouts: knockouts4,
      knockoutsSetter: setKnockouts4,
    },
    {
      value: name5,
      setter: setName5,
      placeholder: "Enter Fighter 5's Name (Optional)",
      knockouts: knockouts5,
      knockoutsSetter: setKnockouts5,
    },
    {
      value: name6,
      setter: setName6,
      placeholder: "Enter Fighter 6's Name (Optional)",
      knockouts: knockouts6,
      knockoutsSetter: setKnockouts6,
    },
  ];

  const handleKnockoutChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    const { knockoutsSetter } = playerNames[index];
    const value = e.target.value ? Number(e.target.value) : null;
    knockoutsSetter(value);
  };

  const handleReset = () => {
    const userConfirmed = window.confirm(
      "Are you sure you want to exit the game? All progress will be lost."
    );

    if (userConfirmed) {
      reset();
      setName1("");
      setName2("");
      setName3("");
      setName4("");
      setName5("");
      setName6("");
      setPunchyIndex(null);
    }
  };

  const handleSubmitScores = () => {
    const knockouts = [
      knockouts1,
      knockouts2,
      knockouts3,
      knockouts4,
      knockouts5,
      knockouts6
    ];
    const validKnockouts = knockouts.map((knockout) => knockout ?? 0);

    recordScoresForRound(validKnockouts);

    setKnockouts1(null);
    setKnockouts2(null);
    setKnockouts3(null);
    setKnockouts4(null);
    setKnockouts5(null);
    setKnockouts6(null);
    setPunchyIndex(null);
  };

  const handleStart = () => {
    const names = [name1, name2, name3, name4, name5, name6];
    const validNames = names.filter((name) => name.trim() !== "");
    if (validNames.length > 2) {
      startGame(validNames);
    } else {
      alert("Please enter more than two players.");
    }
  };

  const startGame = usePunchyKickyStore((state) => state.startGame);
  const gameStarted = usePunchyKickyStore((state) => state.gameStarted);
  const currentRound = usePunchyKickyStore((state) => state.currentRound);
  const players = usePunchyKickyStore((state) => state.players);
  const roundScores = usePunchyKickyStore((state) => state.roundScores);
  const gameEnded = usePunchyKickyStore((state) => state.gameEnded);
  const reset = usePunchyKickyStore((state) => state.reset);

  const calculateSum = (index: number) => {
    return roundScores.reduce(
      (acc, currentRound) => acc + (currentRound[index] ?? 0),
      0
    );
  };

  const handleExit = () => {
    reset();
    setPunchyIndex(null);
    navigate(routesConfig["PUNCH_KICK"].path);
  };

  const renderFinalStandings = () => {
    const standings = players
      .map((player, index) => ({
        name: player,
        score: calculateSum(index),
      }))
      .sort((a, b) => b.score - a.score);

    return standings.map((player, index) => {
      const rank = index + 1;
      const avatar = rankedPlace[rank - 1] || "";
      return (
        <Flex justify="center" mt="4" key={player.name}>
          <Text size="7">
            <strong>{`${rank}${
              ["st", "nd", "rd", "th"][rank > 3 ? 3 : rank - 1]
            }`}</strong>
            {avatar} {player.name} : {player.score}
          </Text>
        </Flex>
      );
    });
  };

  return (
    <Flex direction="column" p="4" className={styles.PunchPage}>
      {!gameStarted ? (
        <>
          <Flex justify="between" align="center">
            <img src={logo} width="50px" />
            <Heading >Score Tracker</Heading>
            <Button
              size="3"
              onClick={handleExit}
            >
              Exit
            </Button>
          </Flex>

          <Flex direction="column" mt="6">
            {playerNames.map((player, index) => (
              <TextField.Root
                key={index}
                mt={index === 0 ? "6" : "3"}
                size="3"
                name="name"
                onChange={(e) => player.setter(e.target.value)}
                value={player.value}
                type="text"
                className={`${styles.input}`}
                placeholder={player.placeholder}
              />
            ))}
          </Flex>

          <Button
            mt="9"
            className={styles.punchyButton}
            size="3"
            onClick={handleStart}
          >
            Start Game
          </Button>
          <Flex mt="6" justify="center" align="center">
          <img src={logo} width="200px"  />
          </Flex>
          
          
        </>
      ) : !gameEnded ? (
        <>
          <Flex justify="between" align="center" mb="6">
            <Heading as="h1" size="8" align="center">
              {currentRound === 5 ? "Final Round" : `Round ${currentRound}`}
            </Heading>
            <Button
              size="3"
              className={styles.punchyButton}
              onClick={handleReset}
            >
              End Game
            </Button>
          </Flex>

          <Flex align="center">
            <Flex justify="start" >
              <Heading as="h2" size="6">
                Player Scores
              </Heading>
            </Flex>
        
          </Flex>

          {players.map((player, index) => (
            <Flex key={index} mt="2" direction="row" align="center">
              <Flex direction="column" width="100%">
                <Text size="3" mb="2">
                  <strong>{player}</strong>
                </Text>
                <TextField.Root
                  size="3"
                  name={`knockouts${index + 1}`}
                  onChange={(e) => handleKnockoutChange(e, index)}
                  value={playerNames[index].knockouts || ""}
                  type="number"
                  className={`${styles.input}`}
                  placeholder="Number of points..."
                />
              </Flex>

              
            </Flex>
          ))}

          <Text mt="6" size="6">
            <strong>Current Scores:</strong>
          </Text>
          {players.map((player, index) => (
            <Text size="5" mt="2" key={index}>
              <strong>{player}</strong>: {calculateSum(index)}
            </Text>
          ))}

          <Button
            mt="5"
            size="3"
            onClick={handleSubmitScores}
          >
            Submit Round {currentRound} Scores
          </Button>
        </>
      ) : (
        <>
          <Heading align="center" size="7" as="h1">
            Well Fought Champ!
          </Heading>

          <Text align="center" size="7" mt="6">
            <strong>Final Standings:</strong>
          </Text>
          {renderFinalStandings()}

          <Flex justify="between" mt="6">
            <Button className={styles.punchyButton} size="3" onClick={reset}>
              Play Again
            </Button>
            <Link to={routesConfig["PUNCH_KICK"].path}>
              <Button className={styles.punchyButton} size="3" onClick={reset}>
                Exit
              </Button>
            </Link>
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default PunchyKickyScoreTracker;
