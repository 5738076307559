import { Flex, Heading } from "@radix-ui/themes";
import CustomCarousel from "../../shared/components/carousel/carousel";
import EmailSubmission from "../../shared/components/email-submission/email-submission";
import MarketingSection from "../../shared/components/marketing-section/MarketingSection";
import carouselData from "../../shared/constants/homeCarousel.json";
import cursedMarketing from "../../shared/constants/cursedTidesMarketingData.json";
import Logo from "/shared/images/CursedTidesLogo.webp";
import styles from "../CursedTides/CursedTides.module.scss";
import ExplainerSection from "../../shared/components/explainer-section/ExplainerSection";
import HowToPlayCursedTides from "../../CursedTides/components/HowToPlayCursedTides/HowToPlayCursedTides";
import RulesDownload from "../../shared/components/rules-download/rulesDownload";

const CursedTides = () => {
  return (
    <>
      <Flex className="MainContainer" direction={"column"}>
        <Flex
          my={"6"}
          p="6"
          justify={"center"}
          align={"center"}
          direction="column"
          className={styles.header}
        >
          <img width="1100px" src={Logo} />
          
          <Flex
            justify="center"
            align="center"
            direction="column"
            mb="9"
            mx="9"
            mt="9"
          >
            <Heading mb="4" as="h2" size={"9"} align="center">
              Rules
            </Heading>

            <RulesDownload productName="CursedTides"  />
          </Flex>
          <Flex
            justify="center"
            align="center"
            direction="column"
            mb="9"
            mx="9"
          >
            <Heading mb="4" as="h2" size={"9"} align="center">
              Card Reference Sheet
            </Heading>

            <RulesDownload productName="CursedTidesCard" cardRef={true} />
          </Flex>
       
        </Flex>

        <MarketingSection data={cursedMarketing} />
        <ExplainerSection
          title="Cursed Tides"
          description="A swashbuckling, backstabbing strategy card game."
          listItems={["2-5 Players", "Ages 8+"]}
          image="/shared/images/CursedBoxNoBG2.webp"
          productName={"CURSED_TIDES"}
        />
        <HowToPlayCursedTides/>

        <EmailSubmission />

        <CustomCarousel cards={carouselData} />
      </Flex>
    </>
  );
};

export default CursedTides;
