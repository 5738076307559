import { Flex, Heading } from "@radix-ui/themes";
import HowToPlayJinx from "../../Jinx/Components/HowToPlayJinx/HowToPlayJinx";
import CustomCarousel from "../../shared/components/carousel/carousel";
import EmailSubmission from "../../shared/components/email-submission/email-submission";
import ExplainerSection from "../../shared/components/explainer-section/ExplainerSection";
import MarketingSection from "../../shared/components/marketing-section/MarketingSection";
import RulesDownload from "../../shared/components/rules-download/rulesDownload";
import carouselData from "../../shared/constants/homeCarousel.json";
import jinxMarketing from "../../shared/constants/jinxMarketingData.json";
import JinxLogo from "/shared/images/JinxLogo2.webp";

const Jinx = () => {
  return (
    <>
      <Flex className="MainContainer" direction={"column"}>
        <Flex direction="column" justify="center" align={"center"}>
          <Flex width={{initial:"300px", md:"800px"}} >
          <img src={JinxLogo} />
          </Flex>
          
          <Flex
            justify="center"
            align="center"
            direction="column"
            mb="9"
            mx="9"
          >
            <Heading mb="4" as="h2" size={"9"} align="center">
              Rules
            </Heading>

            <RulesDownload productName="Jinx" />
          </Flex>
        </Flex>
        <MarketingSection data={jinxMarketing} />

        <ExplainerSection
          title="Jinx"
          description="The Chaotic Card Game That's Shockingly Fun"
          listItems={["3-5 Players", "5 Minute Playtime", "Ages 7+"]}
          image="/shared/images/JinxBox.webp"
          productName={"JINX"}
        />
        <HowToPlayJinx />

        <EmailSubmission />
        <CustomCarousel cards={carouselData} />
      </Flex>
    </>
  );
};

export default Jinx;
