import { Box, Container, Text, Grid, Heading, Flex, Button } from "@radix-ui/themes";
import styles from "./HowToPlayPK.module.scss";
import { Link } from "react-router-dom";
import Tracker from "/shared/images/PunchyTracker.webp";
import Swap from "/shared/images/PunchySwap.webp";
import Punch from "/shared/images/AttackWebsite.webp";
import Scoring from "/shared/images/KnockoutWebsite.webp";
import EndGame from "/shared/images/WinWebsite.webp";
import { routesConfig } from "../../App";

const HowToPlayPK: React.FC = () => {
  return (
    <Box className={styles.HowToPlayPunchyContainer}>
      <Box className={styles.Cover}></Box>
      <Container pb="9" className={styles.ContentContainer}>
        <Flex align="center" flexGrow={"1"} direction={"column"}>
          <Heading as="h2" size={"9"} mb="4">
            HOW TO PLAY
          </Heading>
          <Text align="center" size={{ initial: "5", md: "7" }}>
            Punch and Kick other players to Knock them out over 5 bouts! 
           
          </Text>
          <Text align="center" size={{ initial: "5", md: "7" }}>
          Score the most points to win!
            </Text>


        </Flex>

        <Flex
          align="center"
          direction={{ initial: "column-reverse", md: "row" }}
          pt="5"
        >
          <Flex align="center" justify="center" px="7" className="halfWidth">
          <Link to={routesConfig["PUNCHY_KICKY_SCORE_TRACKER"].path}>
                <img src={Tracker} alt="Score tracker logo" width={"300px"} />
              </Link>
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Setup
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="5">
              Shuffle the deck and deal each fighter 7 cards and place
              the rest of the deck in the center to form a draw pile.
            </Text>
            <Text size={{ initial: "5", md: "7" }} mt="5">
            Get a pen + paper OR use our online Punchy Kicky score tracker:
            </Text>
            <Link to={routesConfig["PUNCHY_KICKY_SCORE_TRACKER"].path}>
                <Button mt="5" size="4">
                  Punchy Kicky Score Tracker
                </Button>
              </Link>
          </Flex>
        </Flex>

        <Grid align="center" columns={{ initial: "1", md: "2" }} pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Tag in some help
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Swap up to 3 cards from the draw pile to make a better fighting
              hand.
            </Text>
          </Flex>
          <Flex align="center" px="7" justify="center">
            <img src={Swap} alt="Starting Graphic" width={"100%"} />
          </Flex>
        </Grid>

        <Flex direction={{ initial: "column-reverse", md: "row" }} pt="5">
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            <img src={Punch} alt="Guess graphic" width={"100%"} />
          </Flex>
          <Flex
            py="9"
            justify={"center"}
            direction={"column"}
            className="halfWidth"
          >
            <Heading as="h2" size={"9"}>
              FIGHT!
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Get ready and start swinging!
            </Text>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Attack players with attack cards.
            </Text>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Defend incoming attacks with defence cards.
            </Text>
          </Flex>
        </Flex>

        <Grid align={"center"} columns={{ initial: "1", md: "2" }} pt="5">
          <Flex py="9" direction={"column"}>
            <Heading as="h2" size={"9"}>
              Scoring
            </Heading>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              If someone cannot defend your attack then you get 2 points!
            </Text>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Only one to make it all the way to the end of the round?
              Congratulations, youre the last fighter standing and get another 2
              points!
            </Text>
            <Text size={{ initial: "5", md: "7" }} mt="3">
              Make use of our handy score tracker if youre struggling to keep track after a few blows.
            </Text>
          </Flex>
          <Flex align={"center"} px="7" justify="center">
            <img src={Scoring} alt="Scoring Graphic" width={"100%"} />
          </Flex>
        </Grid>

        <Flex
          align={"center"}
          direction={{ initial: "column-reverse", md: "row" }}
          pt="5"
        >
          <Flex align={"center"} justify="center" px="7" className="halfWidth">
            <img src={EndGame} alt="Add up Graphic" width={"100%"} />
          </Flex>
          <Flex py="9" direction={"column"} className="halfWidth">
            <Heading as="h2" size={"9"}>
              Winning
            </Heading>
            <Text mt="3" size={{ initial: "5", md: "7" }}>
              The fighter with the most points at the end of the 5th round wins!
            </Text>
          </Flex>
        </Flex>
      </Container>
    </Box>
  );
};

export default HowToPlayPK;
